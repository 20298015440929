import React from "react";
import { Formik, Form, Field } from "formik";
import ContactFromDate from "data/sections/form-info.json";
import HubspotContactForm from "../Hubspot/hubspot-contact-form";

const ContactForm = () => {
  return (
    <section className="contact section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="form md-mb50">
              <h4 className="fw-700 color-font mb-50">Get In Touch.</h4>
              <HubspotContactForm 
                region="na1"
                portalId="40076704"
                formId="5475d9c7-6d28-4c3b-9e66-c3eabc1661d3"
                />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;
